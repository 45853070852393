@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}



@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



* {
  box-sizing: border-box;
}
*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

body {
  font-family: 'Satoshi', '-apple-system', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  background: #fff;
  color: #000;
  overflow-x: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


li{
  list-style: none;
}
a:hover,
a:focus,
a{
  text-decoration: none;
  color: inherit;
}
a,button{
  outline: none;
}
p,h1,h2,h3,h4,h5,h6,ul,li,a,button,input,span,textarea, select{
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
  color: inherit !important;
  font-family: inherit !important;
}
.large-container{
  position: relative;
 width: 100%;
  padding: 25px;
  height: auto;
}

.site-container{
  position: relative;
 width: 80%;
  margin-left: 10%;
  height: auto;
}

@media screen and (max-width:1300px){
  .large-container,
  .site-container{
      width: 90% !important;
      margin-left: 5% !important;
      padding: 10px 0px !important;
  }
}


.large-text{
  font-size: 64px;
  line-height: 110%;
  font-weight: normal !important;
}
@media screen and (max-width:450px){
  .large-text{
    font-size: 50px !important;
  }
}
@media screen and (max-width:350px){
  .large-text{
    font-size: 40px !important;
  }
}
@media screen and (max-width:1000px){
  .Semi-mid-text,
  .big-text{
    font-size: 40px !important;
  }
}
@media screen and (max-width:700px){
  .Semi-mid-text,
  .big-text{
    font-size: 30px !important;
  }
  .mid-text{
    font-size: 20px !important;
  }
  .big-p{
    font-size: 15px !important;
  }
  .p{
    font-size: 15px !important;
  }
}
@media screen and (max-width:450px){
  .p{
    font-size: 14px !important;
  }
}
.big-text{
  font-size: 35px;
  line-height: 130%;
  font-weight: 300;
}

.big-p{
  font-size: 18px;
  line-height: 30px;
  color: #3E3E3E !important;
  margin-top:7px !important;
}
.p{
  font-size: 14px;
  line-height: 150%;
  color: #3E3E3E !important;
  margin-top: 10px !important;
}





/*Button loader */
.loader {
  width: 30px; /* Adjust size as needed */
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}




.Page_Loader{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.Page_Loader img{
  max-width: 50px;
  -webkit-animation: spin 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: spin 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: spin 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: rotate(180deg) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 0.5;
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: rotate(180deg) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 0.5;
  }
}

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: rotate(180deg) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 0.5;
  }
}






@keyframes FadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@-webkit-keyframes FadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@-moz-keyframes FadeIn{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}





@keyframes FadeInTOp{
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes FadeInTOp{
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

@-moz-keyframes FadeInTOp{
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}










@keyframes bounceInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }
  60% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@-webkit-keyframes bounceInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }
  60% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}


@-moz-keyframes bounceInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }
  60% {
    opacity: 1;
    transform: translateY(10px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}


.a-button{
  position: relative;
  width: 258px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  border:1px solid #000 !important;
  justify-content: center;
  text-align: center;
  margin-top: 40px !important;
  gap: 10px;
  grid-gap: 10px;
  font-size: 17px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width:500px){
  .a-button{
    font-size: 15px !important;
  }
}

@media screen and (max-width:350px){
  .a-button{
    width: 100% !important;
  }
}
@media screen and (max-width:300px){
  .a-button img{
    display: none !important;
  }
}
.a-button-1{
  color: #A0B11B !important;
  border-color: #A0B11B !important;
}
.a-button-1:hover{
  background-color: #F8F8F8 !important;
}
.a-button-2{
  color: #fff !important;
  border-color: #fff !important;
}
.a-button-2:hover{
  background-color: rgba(255,255,255,0.1) !important;
}

.a-button-3{
  background-color: #A0B11B;
  border-color: #A0B11B !important;
  color: #fff !important;
}

.a-button-3:hover{
  background-color: #7b8915;
  border-color: #7b8915 !important;
}



















@keyframes heartbeat{
  0% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
  }
  10% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: scale(.91);
      transform: scale(.91);
  }
  17% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(.98);
      transform: scale(.98);
  }
  33% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: scale(.87);
      transform: scale(.87);
  }
  45% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  }



  
@-webkit-keyframes heartbeat{
  0% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
  }
  10% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: scale(.91);
      transform: scale(.91);
  }
  17% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(.98);
      transform: scale(.98);
  }
  33% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: scale(.87);
      transform: scale(.87);
  }
  45% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  }




  
@-moz-keyframes heartbeat{
  0% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
  }
  10% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: scale(.91);
      transform: scale(.91);
  }
  17% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(.98);
      transform: scale(.98);
  }
  33% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: scale(.87);
      transform: scale(.87);
  }
  45% {
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  }





  .aLoginPage{
    background-color: #000 !important;
    min-height: 100vh !important;
    color: #fff !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .aLoginPage .Post_input input{
    border-color: rgba(255,255,255,0.3) !important;
  }
/* Top_NavBar */
.Top_NavBar{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: auto;
    min-height: 50px;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 10px 0px;
    padding-bottom: 15px;
}

.Nav_Content{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    grid-gap: 10px;
}

.Nav_Ll_Dtxt{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
}
.Nav_Ll_Dtxt p{
    font-size: 13px;
    display: inline-flex;
    gap: 5px;
    grid-gap: 5px;
}
.Nav_Ll_Dtxt p span{
    font-weight: 600;
}
.Nav_Ll_Dtxt p a{
    color: #D2D2D2;
}
.Nav_Ll_Dtxt p a:hover{
    text-decoration: underline !important;
}
.Rr_Tt_Ul{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
}
.Rr_Tt_Ul li {
    position: relative;
    display: inline-flex;
    align-items: center;
}
.Rr_Tt_Ul li a{
    position: relative;
    padding: 4px 8px !important;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    gap: 10px;
    grid-gap:10px;
}
.Rr_Tt_Ul li a img{
    max-width: 13px;
}
.Rr_Tt_Ul li a img.angleDown_Icon{
    margin-top: 2px;
}
.Rr_Tt_Ul li a:hover{
    color: #A0B11B !important;
}
.Rr_Tt_Ul li a.contact-btn{
    background: #B1BD3B;
    color: #fff !important;
    padding: 4px 10px !important;
}
.Rr_Tt_Ul li a.contact-btn:hover{
    background: #718100;
}

.Too_Downdd{
    position: absolute;
    top: 100%;
    z-index: 1000;
    width: 700px;
    padding-top: 20px !important;
    left: -200%;
    display: none;
}
.Rr_Tt_Ul li:hover .Too_Downdd{
  display: block;
}
.Too_Downdd_main{
  position: relative;
  width: 100%;
  height: auto;
  min-height: 200px;
  background-color: #fff;
  color: #000 !important;
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  grid-gap: 0px;
  overflow: hidden !important;
}

.Too_Downdd_List{
  position: relative !important;
  padding-top: 10px !important;
}
.Too_Downdd_List li h3{
  padding: 0px 20px !important;
  position: relative;
  margin-bottom: 20px !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #718100 !important;
}
.Too_Downdd_List li{
  width: 100%;
}
.Too_Downdd_List li a{
  position: relative;
  display: block;
  width: 100% !important;
  padding: 10px 20px !important;
  font-size: 15px !important;
  font-weight: normal !important;
  border-bottom: 1px solid rgba(0,0,0,0.1) !important;
  color: #000 !important;
}
.Too_Downdd_Listli a:hover{
  color: #718100 !important;
  border-color: rgba(0,0,0,0.2) !important;
}
.Too_Downdd_List li:last-child a{
  border-bottom: none !important;
}

.NAv_Icon_Dlts{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  overflow: hidden;
}
.NAv_Icon_Dlts img{
  position: relative;
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  object-position: center;
}

.NAv_Icon_Dlts_Main{
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px;
}

.NAv_Icon_Dlts_Main h3{
  font-size: 17px !important;
  margin-bottom: 10px !important;
}

.NAv_Icon_Dlts_Main p{
  font-size: 14px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #373737 !important;
}


.NAv_Icon_Dlts_Main a img{
  width: auto !important;
  height: auto !important;
}

.NAv_Icon_Dlts_Main a{
  margin-top: 10px !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  display: none !important;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
  margin-left: -5px !important;
}



/* Main_navBar */

.Main_navBar{
    position: relative;
    top: 0;
    left: 0;
    background-color: #000;
    color: #fff;
    width: 100%;
    height: auto;
    padding: 20px 0px;
    padding-top: 0px;
    display: none !important;
}
@media screen and (max-width:1100px){
    .Main_navBar{
        padding: 0px !important;
        position: sticky !important;
        z-index: 2000;
        min-height: 50px !important;
    }
}
.First_Mm_Nav{
    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Right_R_Main_Nav,
.Left_L_Main_Nav,
.site-logo{
    position: relative;
}
.site-logo img{
    max-width: 300px;
}
@media screen and (max-width:1200px){
    .site-logo img{
        max-width: 200px !important;
    }
    .First_Mm_Nav{
        height: 50px !important;
    }
}
.Pp_All_Daf{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
}

.search_btn{
    position: relative;
    padding: 8px 12px !important;
    display: none !important;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
    font-size: 17px;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease-in-out;
}
.Show_Search_DropDown .search_btn,
.search_btn:hover{
    background-color: rgba(255,255,255,0.1) !important;
}
@media screen and (max-width:1100px){
    .search_btn{
        font-size: 15px !important;
        display: inline-flex !important;
    }
    .search_btn img{
        width: 15px !important;
    }
}
.Nav_Social_icons{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
    user-select: none;
}
.Nav_Social_icons p{
    font-size: 17px;
    font-weight: 500;
}
@media screen and (max-width:1200px){
    .Nav_Social_icons p{
        font-size: 15px !important;
    }
}
.social_Icons_main{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    gap: 10px;
}
.FootNav_Social_icons{
    position: relative;
    margin-top: 30px;
}
.FootNav_Social_icons p{
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 13px !important;
    margin-bottom: 20px !important;
}
.social_Icons_main a{
    position: relative;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    padding: 5px !important;
}
.social_Icons_main a:hover{
    opacity: 0.8;
}
@media screen and (max-width:1200px){
    .Nav_Social_icons{
        gap: 10px !important;
        grid-gap: 10px !important;
    }
    .social_Icons_main{
        gap: 5px !important;
        grid-gap: 5px !important;
    }
    .social_Icons_main a img{
        width: 15px !important;
        max-height: 15px !important;
    }
}
.Chat_Btn{
    position: fixed;
    display: inline-flex;
    align-items: center;
    padding: 10px 20px !important;
    background-color: #B1BD3B;
    color: #fff !important;
    font-weight: 500 !important;
    gap: 10px;
    grid-gap: 10px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease-in-out;
    z-index: 1500;
    left: 20px;
    bottom: 30px;
    -webkit-animation: heartbeat 2.5s ease-in-out infinite both;
    -moz-animation: heartbeat 2.5s ease-in-out infinite both;
    -o-animation: heartbeat 2.5s ease-in-out infinite both;
    animation: heartbeat 2.5s ease-in-out infinite both;
    animation-delay: 2.5s;
}

@media screen and (max-width:1200px){
    .Chat_Btn{
        font-size: 15px !important;
        padding: 9px 18px !important;
    }
    .Chat_Btn img{
        width: 15px !important;
    }
}
@media screen and (max-width:1100px){
    .Chat_Btn{
        font-size: 14px !important;
        padding: 8px 10px !important;
    }
    .Chat_Btn img{
        width: 14px !important;
    }
}
@media screen and (max-width:350px){
    .Chat_Btn span{
        display: none !important;
    }
}
.Chat_Btn:hover{
    background-color: #718100 !important;
}

.Second_Mm_Nav{
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
.Second_Mm_Nav ul{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
}
.Second_Mm_Nav ul li{
    position: relative;
    display: inline-flex;
    align-items: center;
}
.Second_Mm_Nav ul li a{
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #D2D2D2;
    padding: 10px 0px !important;
    transition: all 0.3s ease-in-out;
}
.Second_Mm_Nav ul li a:hover{
    color: #fff;
    text-decoration: underline !important;
}

.Nav_Body,
.Mobile_nav,
.Nav_Toggler{
    display: none;
}

@media screen and (max-width:1100px){
    .Main_navBar{
        display: block !important;
    }
    .Nav_Social_icons,
    .search_btn span,
    .Top_NavBar,
    .Second_Mm_Nav{
        display: none !important;
    }

    .site-logo img{
        max-width: 150px !important;
    }

    .Pp_All_Daf{
        gap: 10px !important;
        grid-gap: 10px !important;
    }

    .Nav_Toggler{
        position: relative;
        display: inline-flex !important;
        width: 25px;
        height: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .Nav_Toggler::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: translateY(-5px);
        transition: all 0.3s ease-in-out;
    }
    .Nav_Toggler::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: translateY(5px);
        transition: all 0.3s ease-in-out;
    }
    .Active_Mobile_Nav .Nav_Toggler::before{
        transform: rotate(45deg);
    }
    .Active_Mobile_Nav .Nav_Toggler::after{
        transform: rotate(-45deg);
    }


    .Nav_Body{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 70px;
        z-index: 1000;
        background-color: rgba(0,0,0,0.5);
        display: none;
        opacity: 0;
        -webkit-animation: FadeIn 0.3s ease-in forwards;
        -moz-animation: FadeIn 0.3s ease-in forwards;
        animation: FadeIn 0.3s ease-in forwards;
    }
    .Active_Mobile_Nav .Nav_Body{
        display: block !important;
    }

    .Mobile_nav{
        position: fixed;
        display: block !important;
        z-index: 2000;
        background-color: #000;
        color: #fff;
        border-top: 1px solid rgba(255,255,255,0.1);
        width: 300px;
        height: 100%;
        overflow-y: auto;
        top: 70px;
        right: -100%;
        transition: all 0.3s ease-in-out;
        padding: 25px !important;
        padding-bottom: 100px !important;
    }
    .Active_Mobile_Nav .Mobile_nav{
        right: 0% !important;
    }
    .Mobile_nav .Nav_Social_icons{
        display: flex !important;
        width: 100% !important;
    }

    .Mobile_nav .Nav_Ll_Dtxt{
        display: block !important;
        margin-bottom: 20px !important;
    }

    .Mmn_UlL1{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 10px !important;
        display: block;
    }
    .Mmn_UlL1 li{
        position: relative;
        width: 100%;
        height: auto;
    }
    .Mmn_UlL1 li p{
        position: relative;
        width: 100%;
        user-select: none;
        padding: 13px 0px !important;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
    }
    .Mmn_UlL1 li a{
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 0px !important;
        font-size: 13px;
        display: flex;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        color: #D2D2D2;
    }
    .Mmn_UlL1 li a:hover{
        color: #fff !important;
    }

    .Mmn_UlL1 li a.contact-btn{
            background: linear-gradient(120deg, #C26A0B, #FCA03E);
            color: #fff !important;
            justify-content: center;
            text-align: center;
            border-color: transparent;
        }
        .Mmn_UlL1 li a.contact-btn:hover{
            background: linear-gradient(120deg, #e57824, #fc9945);
        }

}

@media screen and (max-width:300px){
    .site-logo img{
        max-width: 100px !important;
    }
}
@media screen and (max-width:500px){
.Mobile_nav{
    width: 100% !important;
}
}



/* search_dropDown */
.search_dropDown{
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    top: 10px;
    z-index: 2500;
    display: none;
}
@media screen and (max-width:500px){
    .search_dropDown{
        width: 100% !important;
        align-items: center;
        justify-content: center;
    }
    .Show_Search_DropDown .search_dropDown{
        display: flex !important;
    }
}
.Show_Search_DropDown .search_dropDown{
    display: block;
}

.search_dropDown_Box{
    position: relative;
    width: 100%;
    height: auto;
    padding: 10px !important;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
    border-radius: 5px;
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
    -webkit-animation: bounceInFromTop 0.6s forwards; 
    -moz-animation: bounceInFromTop 0.6s forwards; 
    animation: bounceInFromTop 0.6s forwards; 
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}

@media screen and (max-width:500px){
    .search_dropDown_Box_Form input,
    .search_dropDown_Box_Form,
    .search_dropDown_Box{
        width: 90% !important;
    }
}



.search_dropDown_Box_Form{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    grid-gap: 5px;
}
.search_dropDown_Box_Form input{
    position: relative;
    width: 300px;
    height: 40px;
    border:1px solid #C0C0C0;
    background-color: #F8F8F8;
    padding: 0px 15px !important;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}
.search_dropDown_Box_Form input:focus{
    background-color: #fff;
    border-color: #718100;
}
.search_dropDown_Box_Form .SearchMain_btn{
    position: relative;
    height: 40px;
    padding: 0px 20px !important;
    background-color: #B1BD3B;
    color: #fff !important;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.search_dropDown_Box_Form .SearchMain_btn:hover{
    background-color:#718100;
}
.search_dropDown_Box_Form .SearchMain_btn img{
    display: none;
}
@media screen and (max-width:300px){
    .search_dropDown_Box_Form .SearchMain_btn{
        padding: 0px 15px !important;
    }
    .search_dropDown_Box_Form .SearchMain_btn img{
        display: inline-flex !important;
    }
    .search_dropDown_Box_Form .SearchMain_btn span{
        display: none !important;
    }
}

.Close_Search_box{
    position: relative;
    width: 20px;
    height: 20px;
    background-color: #F3F3F4;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}
.Close_Search_box:hover{
    background-color: #E4E4E7;
}
.Close_Search_box::before{
    content: '';
    position: absolute;
    width: 70%;
    height: 1px;
    background-color: #000;
    transform: rotate(45deg);
}
.Close_Search_box::after{
    content: '';
    position: absolute;
    width: 70%;
    height: 1px;
    background-color: #000;
    transform: rotate(-45deg);
}






/* Let_talk_sec */
.Let_talk_sec{
    position: fixed;
    top: 130px;
    right: 10%;
    z-index: 2500;
    display: none;
}

@media screen and (max-width:1100px){
    .Let_talk_sec{
        top: 63px !important;
    }  
}

.Show_Let_talk_sec.Let_talk_sec{
    display: block;
}


.Let_talk_Box{
    position: relative;
    width: 300px;
    height: auto;
    min-height: 200px;
    background-color: #fff;
    color: #000;
    overflow: hidden;
    border-radius: 5px;
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
    -webkit-animation: bounceInFromTop 0.6s forwards; 
    -moz-animation: bounceInFromTop 0.6s forwards; 
    animation: bounceInFromTop 0.6s forwards; 
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}
@media screen and (max-width:400px){
    .Let_talk_Box{
        width: 100% !important;
    }
    .Let_talk_sec{
        width: 80% !important;
    }
}

.Let_talk_Banner{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Let_talk_Banner img{
    max-width: 100%;
}
.Let_talk_Banner video{
    position: absolute;
    max-width: 50%;
    bottom: 20px;
    left: 20px;
}

.Close_Let_talk_sec{
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: rgba(0,0,0,0.8);
    top: 15px;
    right: 15px;
    border-radius: 50%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  center;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.Close_Let_talk_sec:hover{
    transform: scale(1.2);
}
.Close_Let_talk_sec::before{
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #fff;
    transform: rotate(45deg);
}
.Close_Let_talk_sec::after{
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #fff;
    transform: rotate(-45deg);
}

.Let_talk_Dlt{
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
}
.Let_talk_Dlt h3{
    font-size: 20px;
    font-weight: 500;
}


/* Ll_btns */

.Ll_btns{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 15px;
    grid-gap: 10px;
}
@media screen and (max-width:400px){
    .Ll_btns{
        display: flex !important;
        flex-direction: column !important;
        grid-gap: 10px !important;
        gap: 10px !important;
    }
    .Ll_btns a{
        font-size: 15px !important;
    }
}
.Ll_btns a{
    position: relative;
    width: 100%;
    height: 45px;
    padding: 0px 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    grid-gap: 5px;
    border:1px solid #DEDEE8 !important;
    border-radius: 5px;
    font-size: 16px;
    white-space: nowrap;
}

.Ll_btns a img{
    width: 20px;
}
.Ll_btns a.whatsapp-btn{
    background-color: #30D14E !important;
    border-color: #30D14E !important;
    color: #fff !important;
}













/* header-carousel */


.header-carousel {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden !important;
}
.carousel-item {
  background-color: #000;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}
.carousel-item::before{
  content: '';
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}

.carousel-item img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}
@media screen and (max-width:1000px){
  .carousel-item img {
      height: 90vh !important;
  }
  .carousel-text {
    width: 100% !important;
  }
}


.carousel-text {
  position: absolute;
  z-index: 100;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #fff;
  width: 80%;
}
.carousel-text .site-container{
  margin-left: -200px;
  transition: all 0.5s ease-in-out;
}
.carousel-text h2{
  font-weight: 700 !important;
}
.carousel-text p{
  color: #fff !important;
  margin-bottom: 30px !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
}
.carousel-text a{
  margin-top: 20px !important;
  background-color: #000 !important;
  border-color: #000 !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.carousel-text a:hover{
  background-color: #131313 !important;
  border-color: #131313 !important;
}
.carousel-text span{
  opacity: 0;
  transform: translateY(50px) scale(1.5);
  display: inline-flex;
}

.slick-slide.slick-active .carousel-item .carousel-text .site-container{
  margin-left: 0px; 
}



/* Customize the dots */
.slick-dots {
 bottom: 20px !important;
  position: absolute !important;
  z-index: 100 !important;
}

.slick-dots li{
  width: 10px !important;
  height: 10px !important;
  margin: 3px 5px !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: #fff !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important; /* Active dot color */
  opacity: 1 !important;
}

/* Arrow styling */
.slick-prev, .slick-next {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute !important;
}
@media screen and (max-width:1000px){
  .slick-prev, .slick-next {
    display: none !important;
  }
}
.slick-prev {
  left: 50px !important;
}

.slick-next {
  right: 50px !important;
}

.slick-next svg{
  margin-left: -20px !important;
  fill: #fff !important;
}
.slick-arrow::before{
  display: none !important;
}

.slick-prev svg{
  fill: #fff !important;
}























@media screen and (max-width:500px){
    .static-text{
        position: relative;
        display: block;
        width: 100%;
    }
}

.Top_Tt_Abt_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin: 50px 0px;
}

@media screen and (max-width:1000px){
    .Top_Tt_Abt_Sec{
        margin-top: 30px !important;
    }
  }

.Top_Tt_Abt_Grid{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}

@media screen and (max-width:1000px){
    .Top_Tt_Abt_Grid{
        display: flex !important;
        flex-direction: column !important;
        gap: 40px !important;
        grid-gap: 40px !important;
    }
    .Abt_Img{
        display: none !important;
    }
}

.Abt_txt h2{
    margin-bottom: 30px !important;
}



.Abt_Img{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Abt_Img img{
    position: relative;
    max-width: 80%;
}


/* Services_Sec */

.Services_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 60px;
}

.Services_contents{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    /* align-items: center; */
}
@media screen and (max-width:800px){
    .Services_contents{
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }
    .Service_card img{
        max-height: 350px !important;
        width: 100% !important;
        object-fit: cover !important;
        object-position: center !important;
    }
    .Services_card_sec{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }
}

.Services_card_sec{
    position: relative;
    width: 100%;
    height: auto;
}

.Service_card{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    color: #fff !important;
    overflow: hidden;
}
.Service_card img{
    position: relative;
    max-width: 100%;
    transition: all 0.5s ease-in-out;
}
.Service_card:hover img{
    transform: scale(1.1);
}

.Service_card::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 0px;
    background: linear-gradient(120deg, #C26A0B, #FCA03E);
    z-index: 5;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}
.Service_card:hover::before{
    height: 10px;
}

.Service_Card_Dlt{
    position: absolute;
    z-index: 100;
    padding:50px 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Service_Card_Dlt span{
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.Service_Card_Dlt p{
    font-size: 30px;
    font-weight: 500;
}
@media screen and (max-width:1200px){
    .Service_Card_Dlt p{
        font-size: 20px !important;
    }
}


.OOla_sec{
    position: relative;
    width: 100%;
    height: auto;
    margin: 50px 0px;
    overflow-x: hidden;
}

.OOla_sec_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
@media screen and (max-width:1000px){
    .OOla_sec_Main{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
    }
}
.OOla_Ll{
    position: relative;
    width: 100%;
}
.OOla_Ll h2{
    margin-bottom: 30px;
}
.OOla_Ll ul{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px !important;
}
.OOla_Ll ul li{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
    margin-top: 10px !important;
}
@media screen and (max-width:500px) {
    .OOla_Ll ul li img{
        max-width: 50px !important;
    }
    .OOla_Ll ul li span{
        font-size: 17px !important;
    }
}
@media screen and (max-width:400px) {
    .OOla_Ll ul li{
        margin-top: 15px !important;
    }
    .OOla_Ll ul li img{
       display: none !important;
    }
}
.OOla_Ll ul li span{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.OOla_Rr{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
@media screen and (max-width:500px){
    .OOla_Rr{
        display: flex !important;
        flex-direction: column !important;
        gap:10px !important;
    }
}
.OOla_Rr_Card{
    position: relative;
    width: 100%;
    height: auto;
    background-color: #F8F8F8;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}
.OOla_Rr_Card p{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px !important;
}
.OOla_Rr_Card span{
    font-size: 13px;
    color: #373737;
}
.OOla_Rr_Card::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    background: linear-gradient(120deg, #C26A0B, #FCA03E);
    z-index: 5;
    bottom: 0;
    left: 0;
}

.Daad_Sec{
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0px;
    background-color: #B1BD3B;
    color: #fff;
    margin-top: 80px !important;
}

.Daad_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}
@media screen and (max-width:1000px){
    .Daad_Main{
        display: flex !important;
        flex-direction: column !important;
        gap: 50px !important;
    }
    .Daad_2 img{
        width: 100% !important;
    }
}

.Daad_1{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.Daad_1 h2{
    margin-bottom: 30px !important;
}
.Daad_1 p{
    color: #fff !important;
}
.Daad_1 span{
    display: block;
    width: 100%;
    margin-top: 30px !important;
    font-size: 17px;
    color: #fff !important;
}

.Daad_2{
    position: relative;
    width: 100%;
    height: auto;
}
.Daad_2 img{
    position: relative;
    max-width: 100%;
}




.news-slider {
    width: 90%;
    margin: 0 auto;
  }
  
  .news-card {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .news-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .news-title {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .news-description {
    font-size: 0.9rem;
    color: #666;
  }

  











  /* Latest_News_Sec */

  .Latest_News_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin: 70px 0px;
  }
  @media screen and (max-width:1000px){
    .Latest_News_Sec{
        margin: 50px 0px !important;
    }
  }
  .latest-news-slider{
    position: relative;
    width: 100%;
    height: auto;
  }
  .Latest_News_Header{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width:600px){
    .Latest_News_Header{
        display: block !important;
    }
    .Slider_Control_Btns{
        margin-top: 10px !important;
    }
  }

  .Slider_Control_Btns{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
  }
  .Slider_Control_Btns button{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border:1px solid #D3D3D3 !important;
    background-color: #F8F8F8;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}
.Slider_Control_Btns button:hover{
    background-color: transparent;
}
.Slider_Control_Btns button img{
    max-width: 40%;
}

.Slider_Control_Btns button.prev-button.inactive,
.Slider_Control_Btns button.next-button.inactive {
  opacity: 0.5;
  pointer-events: none; /* Disable clicking */
}

.Slider_Control_Btns button.prev-button.active,
.Slider_Control_Btns button.next-button.active {
  opacity: 1;
  cursor: pointer;
}

  .Latest_News_Slider{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
    overflow-x: hidden !important;
  }

  .news-card{
    position: relative;
    width: 100%;
    height: auto;
    padding:0px 15px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    text-align: left !important;
    border-radius: 0px !important;
  }

  @media screen and (max-width:600px){
    .news-card{
        padding: 0px !important;
    }
    .News_Card_Main img{
        height: 250px !important;
    }
  }

  .News_Card_Main{
    position: relative;
    width: 100%;
    display: block;
  }

  .News_Card_Main img{
    position: relative;
    width: 100%;
    height: 248px;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
  }
  .News_Card_Main:hover img{
    filter: brightness(0.6);
    -webkit-filter: brightness(0.6);
    -moz-filter: brightness(0.6);
  }

  .News_Card_Dlt{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 15px;
  }
  .News_Card_Dlt h3{
    font-size: 20px;
    font-weight: 500;
  }
  @media screen and (max-width:500px){
    .News_Card_Dlt h3{
        font-size: 15px !important;
    }
  }
 .News_Card_Main:hover .News_Card_Dlt h3{
    text-decoration: underline;
  }

  .News_Card_Dlt h6{
    position: relative;
    display: block;
    margin-top: 8px !important;
    font-size: 16px;
    font-weight: normal;
    color: #718100 !important;
  }

  .News_Card_Dlt p{
    font-size: 15px;
    color: #3E3E3E;
    display: block;
    margin-top: 10px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }



  .more_Btn_Sec{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px !important;
  }



  .review_sec{
    position: relative;
    width: 100%;
    height: auto;
  }

  .review_Box{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 300px;
    background-color: #F8F8F8;
  }
  .review_Box h3{
    font-size: 32px;
    color: #7C7C7C;
    font-weight: normal;
  }




  .faq-section {
    position: relative;
    width: 100%;
    height: auto;
    margin: 70px 0px;
  }

  @media screen and (max-width:1000px){
    .faq-section{
        margin: 50px 0px !important;
    }
  }
  
  .faq_header{
    position: relative;
    width: 100%;
    text-align: center;
  }
  .faq_Main{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
  .faq_Main ul{
    position: relative;
    width: 100%;
    height: auto;
  }
  .faq_Main ul li{
    margin-top: 20px !important;
  }
  .faq-item {
    position: relative;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .faq-content{
    position: relative;
    width: 100%;
    height: auto;
    padding: 16px;
    background-color: #F8F8F8;
    border-radius: 5px;
    color: #373737;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .faq-question h3 {
    font-size: 15px;
    font-weight: 500;
  }
  
  .faq-question span {
    font-size: 20px;
    font-weight: 500;
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    font-size: 15px;
    color: #333;
  }
  .faq-answer p span{
    font-size: 14px !important;
    margin-top: 10px !important;
    display: block !important;
  }
  .faq-answer.show {
    max-height: 500px; 
  }
  
  .active .faq-question h3 {
    font-weight: 600;
    margin-bottom: 10px !important;
    color: #000;
  }
  
  /* .active .faq-question span {
    color: #007bff;
  }
   */


   

   .Gga_Sec{
    position: relative;
    width: 100%;
    height: auto;
   }
   .Gga_Sec .large-container{
    padding-bottom: 0px !important;
   }

   .Gga_Main{
    position: relative;
    width: 100%;
    height: auto;
    padding: 70px 50px;
    background-color: #000;
    color: #fff;
   }
   @media screen and (max-width:700px){
    .Gga_Main{
        padding: 40px 0px !important;
    }
   }

   .Gga_Header{
    text-align: center;
   }


   .Gga_GRd{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
   }

   @media screen and (max-width:1100px){
    .Gga_GRd{
        display: flex !important;
        flex-direction: column !important;
        gap: 40px !important;
    }
   }


   .Gga_GRd_Card{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 80px;
    margin-top: 40px;
   }
   @media screen and (max-width:600px){
    .Gga_GRd_Card{
        grid-gap: 30px !important;
    }
   }
   @media screen and (max-width:500px){
    .Gga_GRd_Card_1 h3{
        font-size: 20px !important;
    }
    .Gga_GRd_Card_1 p{
        font-size: 15px !important;
    }
    .Gga_GRd_Card_0 span{
        width: 40px !important;
        height: 40px !important;
        font-size: 16px !important;
    }
   }
   @media screen and (max-width:400px){
    .Gga_GRd_Card{
        display: block !important;
    }
    .Gga_GRd_Card_0{
        display: none !important;
    }
   }
 
   .Gga_GRd_Card_0{
    position: relative;
    width: auto;
    height: auto;
   }

   .Gga_GRd_Card_0 span{
    position: relative;
    width: 50px;
    height: 50px;
    background-color: #4A4A4A;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 500;
   }
   .Gga_GRd_Card_0 span::before{
    content: '';
    position: absolute;
    width: 1px;
    height: 50px;
    background-color: #4A4A4A;
    top: 135%;
   }

   .Gga_GRd_Card_1{
    position: relative;
    width: 100%;
    height: auto;
   }
   .Gga_GRd_Card_1 h3{
    font-size: 25px;
    font-weight: normal;
   }

   .Gga_GRd_Card_1 p{
    font-size: 17px;
    display: block;
    margin-top: 30px !important;
    color: #D2D2D2;
    line-height: 28px !important;
   }

   .Hha_btn{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px !important;
   }



   .Gga_GRd_2{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .Gga_GRd_2 img{
    max-width: 100%;
   }






   /* site-footer */

   .site-footer{
    position: relative;
    width: 100%;
    height: auto;
    padding: 70px 0px;
    background-color: #F8F8F8;
   }

   .footer-content{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    grid-template-rows:auto;
   }
   @media screen and (max-width:600px){
    .footer-content{
        display: flex !important;
        flex-direction: column !important;
        gap: 30px !important;
        grid-gap: 30px !important;
    }
    .Foot_part ul{
        margin-top: 30px !important;
    }
    .Foot_part h4{
        margin-top: 10px !important;
    }
   }

   .Foot_part{
    position: relative;
    width: 100%;
    height: auto;
   }
   .Foot_part p{
    font-size: 15px;
    display: block;
    margin-top: 10px !important;
    color: #3E3E3E;
   }

   .foot-logo img{
    max-width: 100% !important;
   }
   .Fst_Foot_part{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    grid-gap: 30px;
   }

   .Foot_part h4{
    font-size: 20px;
    font-weight: 500;
    display: block;
    margin-top: 30px !important;
   }
   .Foot_part ul{
    position: relative;
    margin-top: 50px !important;
    width: 100%;
   }
   .Foot_part ul li{
    position: relative;
    margin-top: 15px !important;
   }
   .Foot_part ul a{
    color: #3E3E3E !important;
    font-size: 15px;
    transition: all 0.3s ease-in-out;
   }
   .Foot_part ul a:hover{
    color: #000 !important;
    text-decoration: underline !important;
   }

   .Ffa_PaG .social_Icons_main{
    margin-bottom: 20px;
   }

   .Ffa_PaG p span{
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px !important;
    display: inline-flex;
   }

p.aalk-Ffa{
    margin-top: 30px !important;
    display: block !important;
}










   /* All_Abbt_PG */

   .All_Abbt_PG{
    position: relative;
    width: 100%;
    height: auto;
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    padding: 100px 0px;
    z-index: 1;
    background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(./components/Img/AaaBta-banner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }

   @media screen and (max-width:1200px){
        .All_Abbt_PG_Sec{
            width: 100% !important;
            margin: 0px !important;
            padding: 0px !important;
        }

        .All_Abbt_PG{
            border-radius: 0px !important;
        }
   }

   .All_Abbt_PG video{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -200;
    top: 0;
    left: 0;
   }

   .All_Abbt_PG_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
   }

   @media screen and (max-width:1100px){
    .All_Abbt_PG_Main{
        grid-template-columns: 0.5fr 1fr !important;
    }
   }
   @media screen and (max-width:900px){
    .All_Abbt_PG_Main{
       display: block !important;
    }
   }


   .All_Abbt_Dlt{
    position: relative;
    width: 100%;
    height: auto;
   }
   .All_Abbt_Dlt h2{
    margin-bottom: 30px !important;
   }
   .All_Abbt_Dlt p{
    font-size: 20px;
   }
   @media screen and (max-width:1100px){
    .All_Abbt_Dlt p{
        font-size: 16px !important;
       }
   }
   @media screen and (max-width:500px){
    .All_Abbt_Dlt p{
        font-size: 15px !important;
       }
   }
   @media screen and (max-width:430px){
    .All_Abbt_Dlt p{
        font-size: 14px !important;
       }
   }

   .Mmma_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin: 50px 0px;
   }

   .Mmma_Main{
    position: relative;
   max-width: 1000px;
   }
   @media screen and (max-width:1000px){
    .Mmma_Main{
        max-width: 100% !important;
        width: 100% !important;
    }
    .Mmma_Main p{
        font-size: 20px !important;
    }
   }
   @media screen and (max-width:700px){
    .Mmma_Main p{
        font-size: 17px !important;
        margin-top: 10px !important;
    }
   }
   @media screen and (max-width:500px){
    .Mmma_Main p{
        font-size: 15px !important;
    }
   }
   .Mmma_Main h2{
    margin-bottom: 30px !important;
   }
   .Mmma_Main p{
    margin-top: 10px !important;
    font-size: 18px;
    line-height: 150%;
    color: #3E3E3E;
   }



   .CardSlider_OSj p{
    -webkit-line-clamp: 100 !important;
    line-clamp: 100 !important;
    margin-top: 40px !important;
   }

   .CardSlider_OSj h4{
    padding: 8px 15px !important;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: -65px !important;
    margin-left: 20px !important;
    background: linear-gradient(120deg, #C26A0B, #FCA03E);
    color: #fff !important;
    display: inline-flex !important;
    position: absolute;
   }



   .Part_Sec{
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0px;
    background-color: #F8F8F8;
    margin-top: 50px;
   }
   .Part_Sec .site-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
   }

   .Part_Sec ul{
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 50px !important;
    gap: 50px;
    grid-gap: 50px;
    width: 100%;
    justify-content: center;
    overflow-x: auto;
   }
   .Part_Sec ul li img{
    max-width: 300px;
    max-height: 85px;
    object-fit: contain;
   }

   @media screen and (max-width:500px){
    .Part_Sec ul li img{
        max-width: 200px !important;
    }
   }
   @media screen and (max-width:400px){
    .Part_Sec ul{
        justify-content: flex-start !important;
    }
   }


   .YouCA_Sebx{
    position: relative;
    width: 100%;
    height: auto;
    padding: 150px 0px;
    background-color: #000;
    color: #fff;
   }


   .YouCA_Sebx_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-gap: 80px;
   }

   @media screen and (max-width:1100px){
    .YouCA_Sebx{
        padding: 50px 0px !important;
    }
    .YouCA_Sebx_Main{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }
   }

   .Long_Imgao,
   .Long_Imgao img{
    height: 100% !important;
    object-fit: cover;
    object-position: calc();
   }


   .YouCA_Sebx_Dlt{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
   }

   .YouCA_Sebx_Dlt h2{
    margin-bottom: 30px !important;
   }
   .YouCA_Sebx_Dlt p{
    line-height: 150%;
    font-size: 17px;
   }

   @media screen and (max-width:500px){
    .YouCA_Sebx_Dlt p{
        font-size: 15px !important;
    }
   }





   .career_Sec{
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    padding: 50px 0px;
    padding-bottom: 30px;
   }

   .career-Content{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    grid-gap: 30px;
   }

   @media screen and (max-width:1000px){
    .career-Content{
        grid-template-columns: 1fr 1fr !important;
    }
   }
   @media screen and (max-width:800px){
    .career-Content{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
   }

   .career_Sec::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 75%;
    z-index: -100;
    top: 0;
    left: 0;
    background-color: #000;
    background-image:linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(./components/Img/career-banner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
   }

   .Ccc_oo1{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    padding-top: 60px;
   }

   @media screen and (max-width:800px){
    .Ccc_oo1{
        padding-top: 0px !important;
        text-align: center;
    }
   }

   .Ccc_oo1 p{
    position: relative;
    margin-top: 10px !important;
    font-size: 17px;
    color:#a2a2a2;
   }

   .Ccc_oo2{
    position: relative;
    width: 100%;
    height: auto;
   }

   .Ccc_Form{
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    border-radius: 10px;
   }

   .Ccc_Form h3{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px !important;
   }

   .Ccc_Input{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px !important;
   }
   .Ccc_Input select,
   .Ccc_Input input{
    position: relative;
    width: 100%;
    height: 57px;
    border:1px solid #C0C0C0 !important;
    background-color: #F8F8F8;
    user-select: none;
    border-radius: 5px;
    padding: 0px 15px !important;
    transition: all 0.3s ease-in-out;
   }
   .Ccc_Input label{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px !important;
    display: block;
    color: #3E3E3E;
   }
   .Ccc_Input select:focus,
   .Ccc_Input input:focus{
        border-color: #718100;
   }

   .Ccc_Input input.file-input{
    padding-top: 15px !important;
   }

   .Ccc_Input button{
    position: relative;
    width: 100%;
    height: 57px;
    border:1px solid #A0B11B !important;
    background-color: #A0B11B;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: #fff !important;
    font-weight: 500;
   }
   .Ccc_Input button:hover{
    background-color: #718100;
    border-color: #718100 !important;
   }









   .job-opportunities-section {
    position: relative;
    width: 100%;
    height: auto;
    margin: 70px 0px;
    margin-top: 10px;
  }
  
  @media screen and (max-width:1000px){
    .job-opportunities-section{
        margin: 50px 0px !important;
    }
  }
  
  .job-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width:800px){
    .job-header {
        display: block !important;
    }
    .job-header span{
        margin-top: 10px !important;
        display: block;
    }
  }
  .job-header span{
    font-size: 14px;
    font-weight: normal;
    color: #3E3E3E !important;
  }
  
  .job-main {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
  
  .job-main ul {
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
    grid-template-rows:auto;
  }
  
  @media screen and (max-width:900px){
    .job-main {
        margin-top: 30px !important;
    }
    .job-main ul {
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }
  }
  
  .job-item {
    position: relative;
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  .job-content {
    position: relative;
    width: 100%;
    height: auto;
    padding: 16px !important;
    background-color: #F8F8F8;
    border-radius: 5px;
    color: #373737;
  }
  
  .job-title {
    display: block;
    position: relative;
  }
  
  .job-title h3 {
    font-size: 15px;
    font-weight: 500;
  }
  
  .job-title span {
    font-size: 13px;
    font-weight: 400;
    color: #718100;
    margin: 5px 0px !important;
  }
  
  .job-description {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    font-size: 14px;
    color: #373737;
  }
  
  .job-description.show {
    max-height: 500px; 
  }
  
  .active .job-title h3 {
    font-weight: 600;
    color: #000;
  }
  
  .active .job-description {
    margin-top: 10px !important;
  }

  .toggle-button{
    position: relative;
    z-index: 1;
    margin-top: 10px !important;
    width: auto;
    height: auto;
    padding: 7px 0px !important;
    cursor: pointer;
    font-weight: normal;
    background-color: transparent;
  }
  .toggle-button::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: linear-gradient(120deg, #C26A0B, #FCA03E);
    z-index: 5;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}
.toggle-button:hover::before{
    width: 40%;
}





.TooPT_News_Sec{
    position: relative;
    width: 100%;
    height: auto;
}

.TooPT_News_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 20px;
}

.TooPT_NewsCc_1{
    position: relative;
    width: 100%;
    height: auto;
}

.TooPT_NewsCc_1_Card{
    position: relative;
    width: 100%;
    height: auto;
    padding: 150px !important;
    height: 700px;
    background-color: #000;
    color: #fff !important;
    border-radius: 20px;
    display: flex;
    align-items: center;
    z-index: 1;
    overflow: hidden;
}

@media screen and (max-width:1350px){
    .TooPT_NewsCc_1_Card{
        height: 600px !important;
    }
    .TooPT_NewsCc_2_Card01{
        height: 300px !important;
    }
    .TooPT_NewsCc_2_Card02{
        height: 280px !important;
    }
}
.TooPT_NewsCc_2{
    position: relative;
    width: 100%;
    height: auto;
}
@media screen and (max-width:1200px){
    .TooPT_News_Main{
        display: block !important;
    }
    .TooPT_NewsCc_2{
        display: none !important;
    }

    .TooPT_NewsCc_1_Card{
        height: auto !important;
        min-height: auto !important;
        padding: 50px !important;
    }
}
@media screen and (max-width:500px){
    .TooPT_NewsCc_1_Card{
        padding: 100px 30px !important;
        min-height: 0px !important;
        margin-bottom: -30px !important;
    }
    @media screen and (max-width:400px){
        .TooPT_NewsCc_1_Card{
            padding: 70px 30px !important;
        }
    }
}
.TooPT_NewsCc_2_Card01{
    position: relative;
    width: 100%;
    height: 400px;
    background-color: #000;
    color: #fff !important;
    padding: 30px 50px !important;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    overflow: hidden;
}
.TooPT_NewsCc_2_Card02{
    position: relative;
    width: 100%;
    height: 280px;
    background-color: #000;
    color: #fff !important;
    padding: 30px 50px !important;
    border-radius: 20px;
    margin-top: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    overflow: hidden;
}


.TooPT_NewsCc_1_Card::before,
.TooPT_NewsCc_2_Card01::before,
.TooPT_NewsCc_2_Card02::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -50;
    background-color: rgba(0,0,0,0.5);
    transition: all 0.5s ease-in-out;
}




.TooPT_NewsCc_1_Card video,
.TooPT_NewsCc_2_Card01 img,
.TooPT_NewsCc_2_Card02 img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -100;
    object-fit: cover;
    object-position: center;
    transition: all 0.5s ease-in-out;
}


.TooPT_NewsCc_2_Card01:hover img,
.TooPT_NewsCc_2_Card02:hover img{
    transform: scale(1.1);
}

.TooPT_NewsCc_2_Card01:hover::before,
.TooPT_NewsCc_2_Card02:hover::before{
    background-color: rgba(0,0,0,0.2);
}



.TooPT_NewsCc_1_Card h2{
    margin-bottom: 20px !important;
    font-size: 64px;
    line-height: 110%;
    font-weight: normal !important;
}
.TooPT_NewsCc_1_Card p{
    font-size: 35px;
}
@media screen and (max-width:1300px){
    .TooPT_NewsCc_1_Card p{
        font-size: 20px !important;
    }
}

@media screen and (max-width:1000px){
    .TooPT_NewsCc_1_Card h2{
        font-size: 50px !important;
    }
    .TooPT_NewsCc_1_Card p{
        font-size: 25px !important;
    }
}
@media screen and (max-width:500px){
    .TooPT_NewsCc_1_Card h2{
        font-size: 40px !important;
    }
    .TooPT_NewsCc_1_Card p{
        font-size: 20px !important;
    }
}
@media screen and (max-width:500px){
    .TooPT_NewsCc_1_Card p{
        font-size: 18px !important;
    }
}

.TooPT_NewsCc_2_Card01 h3{
    margin-bottom: 10px !important;
}
.TooPT_NewsCc_2_Card01 p{
    font-size: 15px;
    font-weight: 300;
}
.TooPT_NewsCc_2_Card02 h6{
    text-align: right;
    font-size: 16px;
    font-weight: 500;
}




.Other_News_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 70px;
}
.Other_News_Sec ul{
        position: relative;
        width: 100%;
        height: auto;
        display: grid;
        display: grid;
        grid-template-columns:repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 30px;
        grid-template-rows:auto;
        margin-top: 50px !important;
      }
      
      @media screen and (max-width:900px){
        .Other_News_Sec ul {
            margin-top: 30px !important;
        }
        .Other_News_Sec ul {
            display: flex !important;
            flex-direction: column !important;
            gap: 20px !important;
            grid-gap: 20px !important;
        }
      }

      .Other_News_Sec ul li {
        position: relative;
        width: 100%;
        height: auto;
        padding: 20px !important;
        background-color: #F8F8F8;
        border-radius: 5px;
        color: #373737;
        z-index: 1;
      }

      .Other_News_Sec ul li::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background: linear-gradient(120deg, #C26A0B, #FCA03E);
        z-index: -5;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
    }

    .Other_News_Sec ul li h3{
        font-size: 15px;
        font-weight: 600;
        color: #373737;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 10px !important;
      }
    

      .Other_News_Sec ul li span{
        font-size: 14px;
        color: #718100;
        display: block;
      }

      .Other_News_Sec ul li a{
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 101px;
        height: 35px;
        border-radius: 3px;
        background-color: #A0B11B !important;
        color: #fff !important;
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px !important;
        transition: all 0.3s ease-in-out;
      }

      .Other_News_Sec ul li a:hover{
        background-color: #718100 !important;
      }



      .MAin-news-Sec{
        position: relative;
        width: 100%;
        height: auto;
        padding: 50px 0px;
      }
      
      .Latest_News_Grid{
        position: relative;
        width: 100%;
        height: auto;
        display: grid;
        display: grid;
        grid-template-columns:repeat(auto-fill, minmax(330px, 1fr));
        grid-gap: 50px 20px;
        grid-template-rows:auto;
        margin-top: 50px;
      }
      
      @media screen and (max-width:700px){
        .Latest_News_Grid {
            margin-top: 30px !important;
        }
        .Latest_News_Grid {
            display: flex !important;
            flex-direction: column !important;
            gap: 20px !important;
            grid-gap: 20px !important;
        }
      }



      .Read_News_Sec{
        position: relative;
        width: 100%;
        height: auto;
        margin: 50px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .Read_Box{
        position: relative;
        width: 800px;
      }
      img.rad-Imah{
        position: relative;
        width: 100%;
        margin: 40px 0px;
      }
      .hahahaha_uua{
        padding: 0px 200px;
        text-align: justify;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        width: 1100px;
      }

      @media screen and (max-width:1200px){
        .hahahaha_uua{
          width: 100% !important;
        }
      }

      @media screen and (max-width:1000px){
        .hahahaha_uua{
          padding: 0px !important;
          border:none !important;
          text-align: left !important;
        }
      }

      .GGga_Read_Btn{
        position: relative;
        width: 100%;
        height: auto;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 0px;
        margin-top: 50px;
      }
      .GGga_Read_Btn h2{
        font-size: 17px;
        font-weight: 300;
      }


       .GGga_Read_Btn a,
      .GGga_Read_Btn button{
        background: #A0B11B !important;
        font-size: 14px !important;
        width: auto !important;
        padding: 0px 20px !important;
        margin-top: 0px !important;
        text-transform: uppercase !important;
      }
      .GGga_Read_Btn a:hover,
      .GGga_Read_Btn button:hover{
        background-color: #718100 !important;
      }
      .All_Read_Box{
        position: relative;
        width: 1000px;
        height: auto;
      }

      @media screen and (max-width:900px){
        .All_Read_Box,
        .Read_Box{
            width: 100% !important;
        }
      }
      

      .Read_BOx_header{
        position: relative;
        width: 100%;
        height: auto;
      }

      .Read_BOx_header h3{
        margin-bottom: 10px !important;
      }

      .Read_BOx_header span{
        font-size: 14px;
        color: #718100;
      }
      .Read_BOx_header img{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 20px;
        max-height: 500px;
        object-fit: cover;
        object-position: center;
      }

      .Read_BOx_Body{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 40px !important;
      }
      .Read_BOx_Body li a,
      .Read_BOx_Body p a{
        text-decoration: underline !important;
        color: #B1BD3B !important;
      }

      .Read_BOx_Body h3{
        margin: 30px 0px !important;
        font-size: 20px;
        font-weight: 300;
      }

      .Read_BOx_Body h2{
        margin: 30px 0px !important;
      }

      .Read_BOx_Body p{
        font-size: 15px;
        color: #3E3E3E !important;
        margin-top: 10px !important;
      }

      @media screen and (max-width:400px){
        .Read_BOx_Body p{
            font-size: 14px !important;
          }
      }

      .Read_BOx_Body ul li{
        list-style: disc !important;
        margin-left: 30px !important;
      }

      .Read_BOx_Body ul li b{
        font-weight: 500 !important;
      }

      .Read_BOx_Body img{
        position: relative;
        width: 100%;
        height: auto;
        margin: 20px 0px;
        max-height: 500px;
        object-fit: cover;
        object-position: center;
      }

      .Read_BOx_Body ul{
        display: block;
        margin-top: 20px !important;
      }

      .Read_BOx_Body ul li{
        position: relative;
        width: 100%;
        margin-top: 15px !important;
      }



      .Contact_SeccO{
        position: relative;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 0.7fr 1fr;
        grid-gap: 30px;
        min-height: 100vh;
      }

      @media screen and (max-width:1000px){
        .Contact_SeccO{
            display: block !important;
        }
        .Contact_SeccO_1{
            position: relative !important;
            height: auto !important;
            top: 0px !important;
            padding: 50px !important;
            background-color: #F8F8F8;
            margin: 20px 0px;
            margin-top: 10px !important;
            z-index: 1;
        }
        
        .Contact_SeccO_1::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background: linear-gradient(120deg, #C26A0B, #FCA03E);
        z-index: -5;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
    }
    .Contact_SeccO_1::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background: linear-gradient(120deg, #C26A0B, #FCA03E);
        z-index: -5;
        bottom: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
    }
        .Contact_SeccO_2{
            margin-top: 10px !important;
            margin-bottom: 30px !important;
        }
      }

      @media screen and (max-width:500px){
        .Contact_SeccO_1,
        .Contact_SeccO_2{
            padding: 30px !important;
        }
      }

      .Contact_SeccO_1{
        position: sticky;
        top: 50px;
        z-index: 100;
        left: 0;
        width: 100%;
        height: 550px;
        padding-top: 70px;
      }

      .Contact_SeccO_1 ul{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 40px !important;
      }
      .Contact_SeccO_1 ul li{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 30px !important;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 30px;
      }

      @media screen and (max-width:350px){
        .Contact_SeccO_1 ul li{
            display: flex !important;
            flex-direction: column !important;
            grid: 20px !important;
            grid-gap: 20px !important;
        }
      }

      .Contact_SeccO_1 ul li span{
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border:1px solid #DEDEE8;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .Contact_SeccO_1 ul li span img{
        max-width: 100%;
      }

      .Contact_SeccO_1 ul li .DDl_Div{
        position: relative;
        width: 100%;
        height: auto;
      }

      .Contact_SeccO_1 ul li .DDl_Div h3{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px !important;
      }

      .Contact_SeccO_1 ul li .DDl_Div p{
        font-size: 14px;
        color:#343434;
      }

      .Contact_SeccO_1 ul li .DDl_Div h4{
        font-size: 15px;
        font-weight: 500;
        margin-top: 5px !important;
      }



      .Contact_SeccO_2{
        position: relative;
        width: 100%;
        height: auto;
        min-height: 100%;
        background-color: #A0B11B;
        color: #fff;
        padding: 50px;
      }
      
      .Contu_Form_Box{
        position: relative;
        width: 100%;
        height: auto;
      }

      .Contu_Form_Box_Header{
        position: relative;
        width: 100%;
        height: auto;
      }
      .Contu_Form_Box_Header p{
        display: block;
        margin-top: 20px !important;
        font-size: 17px;
      }

      @media screen and (max-width:500px){
        .Contu_Form_Box_Header p{
            font-size: 15px !important;
        }
      }


      .Contu_Form_main{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 30px;
      }

      @media screen and (max-width:500px){
        .Contu_Form_main{
            margin-top: 10px !important;
        }
      }

      

      .Contu_Form_main .Ccc_Input select,
      .Contu_Form_main .Ccc_Input input{
        background-color: transparent !important;
        border-radius: 0px !important;
        border:none !important;
        border-bottom: 2px solid #fff !important;
        color: #fff !important;
      }

      .Contu_Form_main .Ccc_Input select option{
        color: #000 !important;
      }

      .Ccc_Input textarea::placeholder,
      .Contu_Form_main .Ccc_Input input::placeholder{
        color: #fff !important;
      }
      .Ccc_Input textarea:focus,
      .Contu_Form_main .Ccc_Input select:focus,
      .Contu_Form_main .Ccc_Input input:focus{
        border-width: 1px !important;
      }

      .Contu_Form_main .Ccc_Input button{
        background-color: #000 !important;
        border-color: #000 !important;
      }
      .Contu_Form_main .Ccc_Input button:hover{
        background-color: #212121 !important;
        border-color: #212121 !important;
      }

      .Contu_Form_main .DDG_Flex{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        gap: 30px;
        grid-gap: 30px;
      }

      @media screen and (max-width:500px){
        .Contu_Form_main .DDG_Flex{
            display: block !important;
        }
      }

      .Ccc_Input textarea{
        position: relative;
        width: 100%;
        height: 166px;
        border:2px solid #fff !important;
        color: #fff;
        padding: 10px !important;
        outline: none;
        background-color: transparent;
      }





      /* Mm_services_Sec */

      .Mm_services_Sec{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
      }

      .services_Box{
        position: relative;
        width: 1000px;
        margin: 50px 0px;
      }

      @media screen and (max-width:1000px){
        .services_Box{
            width: 100% !important;
            margin-top: 10px !important;
        }
      }

      .services_Box_Header{
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 30px 0px;
      }
      .services_Box_Header p{
        display: inline-block;
        margin-top: 20px !important;
        font-size: 15px;
        color: #343434;
        line-height: 170%;
      }

      @media screen and (max-width:500px){
        .services_Box_Header p{
            line-height: 150% !important;
        }
      }
      .services_Box_Body{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 70px;
      }

      .services_Box_Card{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 80px;
      }

      @media screen and (max-width:1000px){
        .services_Box_Card{
            display: flex !important;
            flex-direction: column !important;
            gap: 30px !important;
            margin-top: 40px !important;
        }
        .services_Box_Card.Rev_ss_Card{
            flex-direction: column-reverse !important;
        }
        .services_Banner img{
            width: 100%;
            max-width: 100% !important;
        }
        
      }

      .services_Banner{
        position: relative;
        width: 100%;
        height: auto;
      }

      .services_Banner img{
        position: relative;
        max-width: 95%;
        height: auto;
      }

      .services_Dlt{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
      }

      .services_Dlt h3{
        margin-bottom: 30px !important;
      }

      @media screen and (max-width:500px){
        .services_Dlt h3{
            margin-bottom: 20px !important;
        }
        .services_Dlt p{
            line-height: 150% !important;
        }
      }

      .services_Dlt p{
        font-size: 15px !important;
        color: #343434 !important;
        line-height: 170%;
        margin-top: 10px !important;
      }

      a.gga_Ahrf{
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: 20px;
        grid-gap: 20px;
        margin-top: 40px !important;
        color: #A0B11B !important;
        text-transform: uppercase;
        font-size: 14px !important;
        border:1px solid #A0B11B !important;
        padding: 15px !important;
        text-align: center;
      }


      .HHah_ha{
        text-align: center;
      }

      .HHah_ha h3{
        font-size: 20px;
        margin-bottom: 20px !important;
      }

      .HHah_ha ul{
        position: relative;
        margin-top: 100px !important;
        width: 100% !important;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
      }

      .HHah_ha ul li{
        position: relative;
        width: 100% !important;
        text-align: left !important;
      }
      @media screen and (max-width:1000px){
        .HHah_ha ul{
          display: flex !important;
          flex-direction: column !important;
          gap: 30px !important;
        }
        .HHah_ha ul li{
          text-align: center !important;
        }
      }
      .HHah_ha ul li img{
        position: relative;
        width: 50px;
        margin-bottom: 20px;
      }

      .HHah_ha ul li h6{
        font-size: 15px !important;
        text-transform: uppercase !important;
        font-weight: 500 !important;
        margin-bottom: 20px !important;
      }
      .services_Dlt h3{
        font-size: 20px;
        font-weight: 500 !important;
      }

      h3.gna-h3{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      .services_Dlt ul{
        position: relative;
      }
      .services_Dlt ul li{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 15px !important;
      }
      .services_Dlt ul li img{
        max-width: 40px;
      }
      @media screen and (max-width:400px){
        .services_Dlt ul li{
          gap: 10px !important;
          grid-gap: 10px !important;
        }
        .services_Dlt ul li img{
            max-width: 30px !important;
          }
      }
      .services_Dlt ul li span{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
      }



      .oo-ul-pga li{
        list-style: disc !important;
        margin-left: 20px !important;
      }

      .ahhshs_hahge {
        position: relative;
        width: 100%;
        margin-top: 100px;
        text-align: center;
      }


.papoos_sec{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:80px;
  margin-top: 100px;
}

@media screen and (max-width:1000px){
  .papoos_sec{
    display: flex !important;
    flex-direction: column !important;
    gap: 30px !important;
    grid-gap: 30px !important;
  }
}

.papoos_1{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -20px;
}

.papoos_1 .faq_Main,
.papoos_1 .faq-section {
  margin: 0px !important;
}

.papoos_2{
  position: relative;
  width: 100%;
  height: auto;
}

.papoos_2 img{
  max-width: 100%;
}











/* Cookie Consent Banner */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    color: white;
    padding: 15px;
    text-align: center;
    z-index: 9999;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    -webkit-animation: FadeInTOp 0.3s ease forwards;
    -moz-animation: FadeInTOp 0.3s ease forwards;
    animation: FadeInTOp 0.3s ease forwards;
  }
  
  .cookie-consent-content p a{
    text-decoration: underline !important;
  }
  
  .cookie-consent-banner button {
    margin: 5px !important;
    padding: 10px 20px !important;
    background-color: #B1BD3B !important;
    border: none;
    color: white !important;
    cursor: pointer;
    font-size: 15px;
    border-radius: 4px;
    margin-top: 20px !important;
    border:1px solid transparent !important;
    transition: all 0.3s ease-in-out;
  }
  .cookie-consent-banner button:hover{
    opacity: 0.8;
  }
  
  .cookie-consent-banner button.reject-btn{
    background-color: transparent !important;
    border-color: #fff !important;
  }
  .cookie-consent-banner button.settings-btn{
    background-color: #fff !important;
    color: #000 !important;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .cookie-consent-banner {
      font-size: 14px;
      padding: 10px;
    }
    
    .cookie-consent-banner button {
      font-size: 14px;
      padding: 8px 16px;
      width: 45%;
    }
  }
  
  /* Cookie Settings Modal */
  .cookie-settings-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 500px;
    background-color: white;
    padding: 20px;
    z-index: 10000;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .cookie-settings-modal h2 {
    margin-top: 0;
    font-size: 24px;
  }
  
  .cookie-settings-modal button {
    padding: 10px 20px !important;
    background-color: #B1BD3B;
    color: white !important;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .cookie-settings-modal button:hover {
    background-color: #718100;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .cookie-settings-modal {
      width: 90%;
      padding: 15px;
    }
    
    .cookie-settings-modal h2 {
      font-size: 20px;
    }
  
    .cookie-settings-modal button {
      width: 100%;
      font-size: 14px;
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* Cookie Settings Modal */
  .cookie-settings-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    z-index: 10000;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .cookie-settings-modal h2 {
    margin-top: 0;
    font-size: 24px;
    text-align: center;
  }
  
  .cookie-settings-modal p {
    font-size: 16px;
    margin-bottom: 20px !important;
    /* text-align: center; */
    margin-top: 10px !important;
  }
  
  .cookie-settings-modal button {
    padding: 10px 20px;
    background-color: #A0B11B;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 15px;
    display: block;
    width: 100%;
    margin: 10px 0 !important;
    transition: all 0.3s ease-in-out;
  }
  .cookie-settings-modal button:hover{
    background-color: #718100 !important;
  }
  
  .cookie-settings-modal button.close-btn{
    color: #000 !important;
    background-color: #e0e0e0;
  }
  .cookie-settings-modal button.close-btn:hover{
    background-color: #ccc8c8 !important;
  }
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .cookie-settings-modal {
      width: 90%;
      padding: 15px !important;
    }
  
    .cookie-settings-modal h2 {
      font-size: 20px;
    }
  
    .cookie-settings-modal p {
      font-size: 14px;
    }
  
    .cookie-settings-modal button {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .cookie-settings-modal {
      width: 95%;
      padding: 10px;
    }
  
    .cookie-settings-modal h2 {
      font-size: 18px;
    }
  
    .cookie-settings-modal p {
      font-size: 12px;
    }
  
    .cookie-settings-modal button {
      font-size: 12px;
      padding: 8px 16px !important;
    }
  }
  
  
  .cookie-category label input{
    margin-right: 10px !important;
  }
  .cookie-category label span{
    display: flex;
    align-items: center;
  }


  .calendly-badge-widget{
    right: 0% !important;
    left: 20px !important;
  }
  .calendly-badge-widget .calendly-badge-content{
    -webkit-animation: heartbeat 2.5s ease-in-out infinite both;
    -moz-animation: heartbeat 2.5s ease-in-out infinite both;
    -o-animation: heartbeat 2.5s ease-in-out infinite both;
    animation: heartbeat 2.5s ease-in-out infinite both;
    animation-delay: 2.5s;
  }





  /* How_Ll_wo_Sec */


  .How_Ll_wo_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin: 70px 0px;
  }

  .How_Ll_wo_Sec_Top{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
  }

  .How_Ll_wo_Sec_Top ul{
    position: relative;
    height: auto;
    display:inline-flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 50px !important;
  }

  .How_Ll_wo_Sec_Top ul li{
    position: relative;
    user-select: none;
    cursor: pointer;
    padding: 10px 30px !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
  }

  .How_Ll_wo_Sec_Top ul li:hover{
    color: #718100 !important;
  }

  .How_Ll_wo_Sec_Top ul li.active-step-li{
    color: #718100 !important;
    border-color: #718100 !important;
  }

  @media screen and (max-width:1000px){
    .How_Ll_wo_Sec_Top{
      align-items: flex-start !important;
    }
    .How_Ll_wo_Sec_Top ul{
      margin-top: 20px !important;
      width: 100% !important;
      justify-content: flex-start !important;
    }
    .How_Ll_wo_Sec_Top ul li{
      padding: 10px 20px !important;
    }
  }
  .Howw_main_container{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 70px;
    background-color: #F8F8F8;
    padding: 50px;

  }

  @media screen and (max-width:1000px){
    .Howw_main_container{
      display: flex !important;
      flex-direction: column-reverse !important;
      align-items: center !important;
      grid-gap: 40px !important;
      gap: 40px !important;
    }
  }

  .Howw_main_2{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }


  .Howw_main_2 h3{
    margin-bottom:20px !important;
    font-size: 18px;
    font-weight: 500;
    color: #718100 !important;
  }

  .Howw_main_2 p{
    color: #3E3E3E !important;
    display: block;
    margin-top: 15px !important;
  }

  .Howw_main_1{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .Howw_main_1 img{
    max-width: 100%;
  }



.jajja_hadd{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
  margin-bottom: 30px;
}

.jajja_hadd img{
  width:50px;
}

.jajja_hadd h2{
  font-size: 30px;
}
































  


  .ggagap_header{
    position: relative;
    width: 100%;
    height: auto;
    background-color: #000;
    color: #fff;
    background-image:linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(./components/Img/buz-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    padding: 100px 0px;
  }

  .const-bg{
    background-image:linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(./components/Img/const-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .akla_hero{
    position: relative;
    width: 700px;
  }

  @media screen and (max-width:1000px){
    .akla_hero{
      width: 100% !important;
    }  
  }

  .akla_hero h2{
    margin-bottom: 30px !important;
    font-weight: 300 !important;
  }

  .akla_hero p{
    color: #fff !important;
  }

  .akla_hero button{
    background: #B1BD3B !important;
    width: auto !important;
    padding: 0px 30px !important;
    border:none !important;
    transition: all 0.3s ease-in-out !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
  }

  .akla_hero button:hover{
    background: #718100 !important;
}


.TopTt_Secc{
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 0px;
  text-align: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.TopTt_Secc p{
  font-weight: 300 !important;
}

.oaoksk_Sec{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #F8F8F8;
  padding: 100px 150px;
  margin-top: 50px;
}

@media screen and (max-width:1300px){
  .oaoksk_Sec{
    padding: 50px !important;
  }
  .hwtata_1{
    width: 100% !important;
    margin-left: 0% !important;
  }
}

@media screen and (max-width:1000px){
  .oaoksk_Sec{
    padding: 0px !important;
    background-color: transparent !important;
  }
}

.hwtata_1{
  position: relative;
  width:70%;
  margin: 40px 0px;
  margin-left: 15%;
  height: auto;
  text-align: center;
}
@media screen and (max-width:1200px){
  .hwtata_1{
    width: 100% !important;
  }
}
.hwtata_1 h2{
  margin-bottom: 30px !important;
}


.hwtata_2{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width:1000px){
  .hwtata_2{
    display: block !important;
  }
}
.hwtata_2_Card{
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px;
  -webkit-box-shadow: 0 0 30px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 0 30px rgb(0 0 0 / 15%);
  box-shadow: 0 0 30px rgb(0 0 0 / 15%);
  background-color: #fff;
  margin-top:30px;
}


.hwtata_2_Card h3{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  grid-gap: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 40px !important;
}

.hwtata_2_Card h3 img{
  max-width: 40px;
}
.hwtata_2_Card p{
  color:#3E3E3E !important;
  font-size: 14px !important;
}
.hwtata_2_Card ul{
  position: relative;
  width: 100%;
  height: auto;
  display: block !important;
  margin-top: 30px !important;
}

.hwtata_2_Card ul li{
  position: relative;
  margin-top: 10px !important;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  font-size: 14px !important;
}

.hwtata_2_Card ul li span{
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #A0B11B;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 7px !important;
}

.hwtata_2_Card ul li span img{
  width: 10px;
}

.hwtata_2_Card  a{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px !important;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  background-color: #A0B11B;
  color: #fff !important;
}











/* jjanag_Sec */


.jjanag_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin: 80px 0px;
  display: grid;
  grid-template-columns:repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 30px;
  grid-template-rows:auto;
}
@media screen and (max-width:1000px){
  .jjanag_Sec{
    display: flex !important;
    flex-direction: column !important;
    gap: 30px !important;
  }  
}
.jjanag_Box{
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px;
  -webkit-box-shadow: 0 0 30px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 0 30px rgb(0 0 0 / 15%);
  box-shadow: 0 0 30px rgb(0 0 0 / 15%);
  background-color: #fff;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
}

.jjanag_Box_1{
  position: relative;
}

@media screen and (max-width:1000px){
  .jjanag_Box{
    display: flex !important;
    flex-direction: column !important;
    gap:20px !important;
  }
}

.jjanag_Box_1 span{
  position: relative;
  width: 70px;
  height: 70px;
  background: #A0B11B;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.jjanag_Box_1 span img{
  max-width: 60% !important;
}

.jjanag_Box_2{
  position: relative;
  width: 100%;
  height: auto;
}

.jjanag_Box_2 h3{
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px !important;
}


.jjanag_Box_2 p{
  font-size: 14px !important;
  color: #343434 !important;
}


.jjanag_Box_2  a{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
  margin-top: 20px !important;
  text-transform: uppercase;
  color: #718100 !important;
  font-size: 14px;
}

.jjanag_Box_2  a img{
  width: 12px !important;
}






/* chuusu_Sec */

.chuusu_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin: 100px 0px !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media screen and (max-width:1000px){
  .chuusu_Sec{
    display: flex !important;
    flex-direction: column !important;
    gap: 40px !important;
    grid-gap: 40px !important;
  }
  .chuusu_1{
    padding: 50px 0px !important;
  }
  .chuusu_1:first-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-right: none !important;
    padding-bottom: 60px !important;
  }
  .chuusu_Sec{
    margin: 50px 0px !important;
  }
}

.chuusu_1{
  position: relative;
  width: 100%;
  height: auto;
  padding:0px 50px;
}

.chuusu_1:first-child{
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.chuusu_1 h2{
  margin-bottom: 40px !important;
}

.chuusu_1 p{
  position: relative;
  margin-top: 20px !important;
  display: block !important;
  font-size: 15px !important;
}
.chuusu_1 ul{
  position: relative;
  margin-top: 10px !important;
}
.chuusu_1 ul li{
  position: relative;
  margin-top: 10px !important;
  display: block !important;
  font-size: 15px !important;
}


.jahhsh_Sec{
  position: relative;
  width: 100%;
  height: auto;
  padding: 100px;
  margin-bottom: 100px;
  background: #F8F8F8;
}
@media screen and (max-width:1000px){
  .jahhsh_Sec{
    padding: 50px !important;
  }
}
.jahhsh_Sec h2{
  margin-bottom: 20px !important;
}

.jahhsh_Sec button,
.jahhsh_Sec a{
  background-color: #A0B11B !important;
  border-color: #A0B11B !important;
  color: #fff !important;
  width: auto !important;
  padding: 0px 20px !important;
  font-size: 14px !important;
}

.jahhsh_Sec button:hover,
.jahhsh_Sec a:hover{
  background-color: #718100 !important;
  border-color: #718100 !important;
}



/* FFf_Sectta */

.FFf_Sectta{
  position: relative;
  width: 100%;
  height: auto;
  padding: 100px 0px;
  background-color: #000;
  color: #fff;
  background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(./components/Img/faq-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.calling-bg{
  background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(./components/Img/calling-bg.jpg) !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.recruite-bg{
  background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(./components/Img/recruite-bg.jpg) !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.ffaf_Hero{
  position: relative;
  width: 600px;
}

@media screen and (max-width:1000px){
  .ffaf_Hero{
    width: 100% !important;
  }
}
.ffaf_Hero h2{
  margin-bottom: 20px !important;
}

.ffaf_Hero a{
  text-transform: uppercase !important;
  background-color: #B1BD3B !important;
  border-color: #B1BD3B !important;
}

.ffaf_Hero a:hover{
  background-color: #718100 !important;
  border-color: #718100 !important;
}






















.quest-box .jjanag_Box .jjanag_Box_1 span{
  background: #F8F8F8 !important;
}

.quest-box .jjanag_Box .jjanag_Box_1 span img{
  width: 30px !important;
}



















/* hahgshsa_Sec */

.hahgshsa_Sec{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 80px;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media screen and (max-width:1000px){
  .hahgshsa_Sec{
    display: flex !important;
    flex-direction: column !important;
    gap: 50px !important;
    grid-gap: 50px !important;
  }
  .Sact_RIght{
    position: relative !important;
    top: 0px !important;
  }
}
@media screen and (min-width:1000px){
.hahgshsa_Sec .big-text{
  font-size: 20px;
}

}

.Sact_RIght{
  position: sticky;
  top: 100px;
  width: 100%;
  height: auto;
  min-height: 300px;
  left: 0;
  z-index: 100;
}

.Sact_RIght_Box{
  position: inherit;
  height: inherit;
  width: inherit;
  min-height: inherit;
  top: inherit;
  left: inherit;
  z-index: inherit;
  background-color: #F8F8F8;
  padding: 30px;
}
.Sact_RIght_Box a:hover,
.Sact_RIght_Box a{
  background-color: #A0B11B !important;
  border-color: #A0B11B !important;
}
.Sact_RIght_Box ul{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px !important;
}

.Sact_RIght_Box ul li{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px !important;
}

.Sact_RIght_Box ul li span{
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border:1px solid #B1BD3B !important;
  border-radius: 50%;
  margin-right: 15px !important;
}

.Sact_RIght_Box ul li span img{
  width: 40%;
}

.Sact_Left{
  position: relative;
  width: 100%;
  height: auto;
}

.All_Fliaa_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 70px;
}
.All_Fliaa_Grid{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  display: grid;
  grid-template-columns:repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  grid-template-rows:auto;
  margin-top: 50px;
}

@media screen and (max-width:600px){
  .All_Fliaa_Grid{
    display: flex !important;
    flex-direction: column !important;
    gap: 30px !important;
    grid-gap: 30px !important;
  }
  .All_Fliaa_Box img{
    height: auto !important;
  }
}

.All_Fliaa_Sec:first-child{
  margin-top: 0px !important;
}

.All_Fliaa_Box{
  position: relative;
  width: 100%;
  height: auto;
}
.All_Fliaa_Box img{
  position: relative;
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px !important;
}
.All_Fliaa_Box p{
  position: relative;
  display: block;
}

.All_Fliaa_Box h6{
  font-size: 15px;
  font-weight: 500 !important;
}





.elfsight-app-babcf0f2-d76e-4122-bc5f-e97a4c7e6287 a{
  display: none !important;
}
.job-description ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}

.job-description ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin: 10px 0;
}

.job-description li {
  margin-bottom: 5px;
}

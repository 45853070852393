/* AdminPage */
.AdminPage{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #0F0F0F;
    color: #fff;
    z-index: 1;
}

.Main_Admin_Page{
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px;
    padding-left: 630px;
}

.Main_Admin_Page::before{
    content: '';
    position: fixed;
    width: 100px;
    height: 100px;
    background-image: url(./Img//dsgn.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    right: 30px;
    z-index: -1000;
}

@media screen and (max-width:1250px){
    .Main_Admin_Page::before{
        display: none !important;
    }
    .Main_Admin_Page{
        padding: 30px !important;
    }
}
.AdminNav{
    position: relative;
    z-index: 2000;
}
.AdminLeftNav1{
    position: fixed;
    width: 120px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #070707;
    color: #fff;
}

.Aa_TopTt{
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Aa_TopTt span{
    position: relative;
    width: 45px;
    height: 45px;
    background-color: #E2EA46;
    color: #000 !important;
    border-radius: 50%;
    font-size: 35px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Playfair", serif;
}

.Gga_Ull_ul{
    position: relative;
    width: 100%;
    height: auto;
    padding: 0px 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 150px;
}

.Gga_Ull_ul ul{
    position: relative;
    width: 100%;
    height: auto;
}
.Gga_Ull_ul ul:last-child{
    border-top: 1px solid #3C3C3C !important;
    border-bottom: 1px solid #3C3C3C !important;
}
.Gga_Ull_ul ul li{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5px !important;
}
.Gga_Ull_ul ul li button,
.Gga_Ull_ul ul li a{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 0px !important;
}
.Gga_Ull_ul ul li button img,
.Gga_Ull_ul ul li a img{
    position: relative;
    opacity: 0.4;
    transition: all 0.3s ease-in-out;
}
.Gga_Ull_ul ul li button:hover img,
.Gga_Ull_ul ul li a.Active_AAD_Icn img,
.Gga_Ull_ul ul li a:hover img{
    opacity: 1;
}
.Gga_Ull_ul ul li button span,
.Gga_Ull_ul ul li a span{
    position: relative;
    font-size: 13px;
    color: #606060 !important;
    display: inline-flex;
    margin-top: 10px !important;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
}
.Gga_Ull_ul ul li button:hover span,
.Gga_Ull_ul ul li a.Active_AAD_Icn span,
.Gga_Ull_ul ul li a:hover span{
    color: #fff !important;
}

.Gga_Ull_ul ul li button{
    background-color: transparent !important;
}
.AdminLeftNav2{
    position: fixed;
    width: 453px;
    left: 120px;
    height: 100%;
    top: 0;
    background-color: #070707;
    border-left: 1px solid #222222 !important;
    border-right: 1px solid #222222 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 50px;
    overflow-y: auto;
}
.HHaggs_TOppso.HHaggs_TOppso_Pc{
    display: none !important;
}

@media screen and (max-width:1250px){
    .AdminLeftNav2{
        width: 100% !important;
        left: 0px !important;
        padding-left: 120px !important;
    }
}

@media screen and (max-width:1250px){
    .HHaggs_TOppso{
        display: none !important;
    }
    .aA_Logo img{
        max-width: 100px !important;
    }
    .HHaggs_TOppso.HHaggs_TOppso_Pc{
        display: flex !important;
        position: sticky !important;
        top: 0;
        left: 0;
        z-index: 1000;
        height: 70px !important;
        background-color: #070707;
    }

    .AdminLeftNav1{
        transition: all 0.3s ease-in-out;
        left: -100% !important;
        z-index: 2300;
    }
    .AdminLeftNav2{
        transition: all 0.3s 0.1s ease-in-out;
        left: -100% !important;
        z-index: 2000;
    }
    .active_AaNav .AdminLeftNav1,
    .active_AaNav .AdminLeftNav2{
        left: 0% !important;
    }

    .Mobile_BabnS{
        display: flex !important;
    }

    .kkajs-ha{
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: 15px;
        grid-gap: 15px;
    }

    .MoNBl_toggle_AaNAv{
        position: relative;
        width: 25px;
        height: 20px;
        background-color:transparent;
        cursor: pointer;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .MoNBl_toggle_AaNAv::before{
        content: '';
        position: absolute;
        width: 90%;
        height: 2px;
        background-color: #fff;
        transform: translateY(-5px);
    }
    .MoNBl_toggle_AaNAv::after{
        content: '';
        position: absolute;
        width: 90%;
        height: 2px;
        background-color: #fff;
        transform: translateY(5px);
    }
}

@media screen and (max-width:500px){
    .cbalks span{
        display: none !important;
    }
    .cbalks{
        display: flex;
        align-items: center;
        padding-top: 0px !important;
    }
    .Baggbs_sec img{
        width: 35px !important;
        height: 35px !important;
    }
}
@media screen and (max-width:350px){
    .Baggbs_sec{
        display: block !important;
    }
    .cbalks p{
        display: none !important;
    }
}

.HHaggs_TOppso{
    position: relative;
    width: 100%;
    height: 90px;
    border-bottom: 1px solid #222222;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px !important;
}

.aA_Logo img{
    max-width: 122px;
}

.Baggbs_sec{
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
}
.Baggbs_sec img{
    position: relative;
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
}

.cbalks{
    position: relative;
    width: 100%;
    padding-top: 5px !important;
}
.cbalks p{
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
}
.cbalks span{
    font-size: 12px;
    color: #6B6B6B;
}

.akkjsks_sec{
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px !important;
}

.banbsg{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
@media screen and (max-width:550px){
    .banbsg{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }
}

.banbsg_1{
    position: relative;
    width: 100%;
    height: auto;
}
.banbsg_1 p{
    font-size: 13px;
    font-weight: 500;
}
.banbsg_1 .banbsg_1_Box{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px !important;
    border-radius: 10px;
    background-color: #000;
    height: 170px;
    margin-top: 15px !important;
}

.banbsg_1 .banbsg_1_Box span{
    position: absolute;
    width: 77px;
    height: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    left: 20px;
    bottom: 10px;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
    background-color: rgba(255,255,255,0.2);
    border:1px solid #FCFCFC;
    border-radius: 30px;
}

.banbsg_1 .banbsg_1_Box img{
    max-width: 50px;
    transition: all 0.3s ease-in-out;
}
.banbsg_1 .banbsg_1_Box:hover img{
    transform: scale(1.1);
}

.banbsg_1_Box_1{
    background: linear-gradient(120deg, #D031BE, #BB166F) !important;
    -webkit-box-shadow: 0 0 20px rgb(208 49 190 / 30%);
    -moz-box-shadow: 0 0 20px rgb(208 49 190 / 30%);
    box-shadow: 0 0 20px rgb(208 49 190 / 30%);
}
.banbsg_1_Box_2{
    background: linear-gradient(120deg, #4DCBFF, #1258E1) !important;
    box-shadow: 0 0 20px rgb(77 203 255 / 30%);
    -webkit-box-shadow: 0 0 20px rgb(77 203 255 / 30%);
    -moz-box-shadow: 0 0 20px rgb(77 203 255 / 30%);
}


.messageAlert{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
}

@media screen and (max-width:500px){
    .messageAlert{
        display: none !important;
    }
}

.messageAlert h4{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #606060;
}

.messageAlert_Box{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 15px !important;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    background-color: rgba(255,255,255,0.1) !important;
    border:1px solid rgba(255,255,255,0.15) !important;
    padding: 13px 30px !important;
    padding-right: 20px !important;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}
.messageAlert_Box:hover{
    background-color: rgba(255,255,255,0.15) !important;
}
.messageAlert_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.messageAlert_Main p{
    font-size: 14px;
}
.messageAlert_Main span{
    font-size: 12px;
    color: #7E7E7E !important;
}

.messageAlert_Icon{
    position: relative;
    display: flex;
    align-items: center;
}

@media screen and (max-width:550px){
    .messageAlert_Icon{
        display: none !important;
    }
}

.Mobile_BabnS{
    position: relative;
    width: 100%;
    height: auto;
    display:none;
    justify-content: flex-end;
    padding:0px 30px !important;
    padding-top: 20px !important;
}

.close_AdimAa_Btn{
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
}
.close_AdimAa_Btn::before{
    content: '';
    position: absolute;
    width: 90%;
    height: 2px;
    background-color: #fff;
    transform: rotate(45deg);
}
.close_AdimAa_Btn::after{
    content: '';
    position: absolute;
    width: 90%;
    height: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
}

/* visitors_Btn_Sec */
.visitors_Btn_Sec{
    position: relative;
    width: 100%;
    height: auto;
    padding:10px 30px !important;
}
.visitors_Btn{
    position: relative;
    z-index: 100;
   width: 138px;
   height: 38px;
   display: inline-flex;
   align-items: center;
   gap: 10px;
   grid-gap: 10px;
   justify-content: center;
   text-align: center;
   border:1px solid #4285F4 !important;
   border-radius: 40px;
   color: #8AB4F8 !important;
   transition: all 0.3s ease-in-out;
}

.visitors_Btn:hover{
    background-color: rgba(255,255,255,0.1) !important;
}





/* aPage */

.aPage{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 90vh;
    display: flex;
    align-items: center;
}

@media screen and (max-width:500px){
    .aPage{
        min-height: 0px !important;
        margin-top: 70px !important;
    }

    .aHome_page h3{
        font-size: 25px !important;
    }
}

.aHome_page{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid #222222 !important;
    width: 100%;
}

.aHome_page h3{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 5px !important;
}

.aHome_page p{
    font-size: 14px;
    color: #6B6B6B;
}
.aHome_page a{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 39px;
    background-color: #718100 !important;
    margin-top: 10px !important;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
}
.aHome_page img{
    max-width: 100%;
}


.Aa_Header{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
    padding: 10px 0px !important;
}
.Aa_Header h3{
    font-size: 23px;
    font-weight: 500;
}
.Aa_Header span{
    font-size: 14px;
    color: #6B6B6B;
}


/* aNNagPag */

.aNNagPag{
    position: relative;
    width: 100%;
    height: auto;
}


.Mmss_sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px !important;
}

.message_Box{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100px;
    background-color: rgba(255,255,255,0.1) !important;
    border:1px solid rgba(255,255,255,0.15) !important;
    border-radius: 10px;
    /* border-bottom-left-radius: 0px; */
    padding: 30px !important;
    margin-top: 20px !important;
}


.message_Box_Tt{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width:500px){
    .message_Box_Tt{
        flex-direction: column !important;
        justify-content: flex-start !important;
        gap: 10px !important;
        grid-gap: 10px !important;
        text-align: center !important;
    }
}
.message_Box_Tt_1 span{
    font-size: 13px;
    color: #939292;
}

.message_Box_Tt_2{
    position: relative;
}

.All_Aaa_Btns{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
}
.Allja_width{
    width: 100%;
    justify-content: flex-end !important;
    margin-bottom: 10px !important;
}
@media screen and (max-width:700px){
    .All_Aaa_Btns{
        flex-direction: column-reverse !important;
        justify-content: center !important;
        text-align: center !important;
    }
}
.All_Aaa_Btns .Gg_btn{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px 10px !important;
    height: 30px;
    font-size: 12px;
    background-color: transparent;
    border:1px solid rgba(255,255,255,0.1) !important;
    gap: 10px;
    grid-gap: 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
}
.All_Aaa_Btns .Gg_btn:hover{
    background-color: rgba(255,255,255,0.1) !important;
}
.All_Aaa_Btns .Gg_btn img{
    width: 15px;
}
.All_Aaa_Btns .Gg_btn.main_Gg_btn{
    background-color: #718100;
    border-color: #718100;
}
.All_Aaa_Btns .Gg_btn.main_Gg_btn:hover{
    background-color: #859602;
    border-color: #859602;
}

.reply_box{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px !important;
}
.reply_box textarea{
    position: relative;
    width: 100%;
    height: 200px;
    resize: none;
    padding: 20px !important;
    background-color: transparent !important;
    border: 1px solid rgba(255,255,255,0.2) !important;
    background-color: rgba(255,255,255,0.04) !important;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}
.reply_box textarea:focus{
    border-color: #fff !important;
}
.reply_box textarea::placeholder{
    color: #a9a9a9 !important;
}
.reply_box button{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 7px 10px !important;
    margin-top: 10px !important;
    background-color: transparent !important;
    border:1px solid rgba(255,255,255,0.1) !important;
    gap: 10px;
    grid-gap: 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.reply_box button:hover{
    background-color: rgba(255,255,255,0.1) !important;
}

.message_Body{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px !important;
}
.message_Body_ttaay{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    grid-gap: 10px;
}
@media screen and (max-width:500px){
    .message_Body_ttaay{
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
}
.message_Body_ttaay h3{
    font-size: 20px;
    font-weight: 500;
}
.message_Body_ttaay a{
    font-size: 13px;
    color: #a9a9a9;
}
.message_Body_ttaay a:hover{
    color: #fff;
    text-decoration: underline;
}

.message_Body ul{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}
.message_Body ul li{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px !important;
    display: flex;
    gap: 15px;
    grid-gap: 15px;
    font-size: 13px;
}
@media screen and (max-width:700px){
    .message_Body ul li{
        display: block !important;
    }
    .message_Body ul li p{
        margin-top: 5px !important;
    }
    .message_Body ul li span{
        font-size: 10px !important;
    }
}

.message_Body ul li span{
    font-size: 12px;
    text-transform: uppercase;
    color: #a9a9a9;
}

.messaget_ooaoa{
    position: relative;
    margin-top: 10px !important;
    font-size: 14px;
    padding: 15px 0px !important;
    border-top: 1px solid rgba(255,255,255,0.1) !important;
}






.aNNagPage .job-main ul li .job-content{
    background-color: rgba(255,255,255,0.03) !important;
    border:1px solid rgba(255,255,255,0.1) !important;
    color: #fff !important;
    cursor:default !important;
}

.aNNagPage .job-main ul li .job-content .job-title h3{
    color: #fff !important;
}

.aNNagPage .Read_Box p,
.aNNagPage .Latest_News_Grid p,
.aNNagPage .job-main ul li .job-content .job-description p{
    color: #a9a9a9 !important;
}

.aNNagPage .job-main {
    margin-top: 10px !important;
}


.aNNagPage .Latest_News_Grid h3{
    text-decoration: none !important;
}

.aNNagPage .Read_Box{
    width: 100% !important;
}



.AaPost_Sec{
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px !important;
    margin-top: 20px !important;
    background-color: rgba(255,255,255,0.03) !important;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 10px;
}

.Post_input{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px !important;
}
.Post_input textarea,
.Post_input input{
    position: relative;
    width: 100%;
    height: 55px;
    padding: 0px 15px !important;
    border:1px solid rgba(255,255,255,0.1) !important;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    background-color: transparent !important;
}
.Post_input textarea{
    height: 200px;
    resize: none;
    padding: 15px !important;
}
.Post_input input:focus,
.Post_input textarea:focus{
    border-color: #fff !important;
}
.Post_input input::placeholder,
.Post_input textarea::placeholder{
    color: #a9a9a9 !important;
}

.Post_input button{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 7px 10px !important;
    background-color:#718100;
    gap: 10px;
    grid-gap: 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 10px 30px !important;
    font-size: 16px;
}

.Post_input button:hover{
    background-color: #859602;
}

.file_naBba input{
    padding-top: 15px !important;
}
.file_naBba label{
    font-size: 15px;
    margin-bottom: 10px !important;
    display: block;
}

















  

  .Hha_Tbale {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px !important;
  }
  
  .Hha_Tbale thead {
    background-color: rgba(255,255,255,0.1);
  }
  
  .Hha_Tbale thead th {
    padding: 10px !important;
    text-align: left;
    border-bottom: 2px solid rgba(255,255,255,0.2) !important;
    font-weight:600;
  }
  

 .Hha_Tbale  tbody tr:nth-child(even) {
    background-color: rgba(255,255,255,0.03) !important;
  }
  

  
  .Hha_Tbale tbody td {
    padding: 20px !important;
    border-bottom: 1px solid rgba(255,255,255,0.2) !important;
  }
  

 .Hha_Tbale table, .Hha_Tbale th, .Hha_Tbale td {
    border: 1px solid rgba(255,255,255,0.2) !important;
    font-size: 14px;
  }
  


  .aLoginPage{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 50px 20px !important;
  }

  .Login_Box{
    position: relative;
    width: 350px;
  }

  @media screen and (max-width:500px){
    .aLoginPage{
        padding:10px 30px !important;
    }
    .Login_Box{
        width: 100% !important;
    }
    .Login_Box h3{
        font-size: 25px !important;
    }
  }
  @media screen and (max-width:450px){
    .Login_Box h3{
        font-size: 20px !important;
    }
  }

  .Login_Box h3{
    font-size: 30px;
    font-weight: 500;
  }

  @media screen and (max-width:400px){
    .Login_Box .Post_input input{
       width: 100% !important;
    }
    .Login_Box .Post_input button{
        width: 100% !important;
        font-size: 15px !important;
     }
  }

  .pagination-buttons{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
  }
  .pagination-buttons button{
    position: relative;
    width: 100px !important;
    height: 40px !important;
    font-size: 13px !important;
    text-transform: uppercase !important;
    color: #fff !important;
    margin: 0px !important;
    background-color: transparent !important;
    border:1px solid rgba(255,255,255,0.5) !important;
    cursor: pointer !important;
  }

  .pagination-buttons button.btn-danger{
    background-color: rgba(255,255,255,0.15) !important;
    border:none !important
  }
  .pagination-buttons button:hover{
    background-color: rgba(255,255,255,0.2) !important;
  }
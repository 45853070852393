/* MessageEditor.css */

/* Set the editor container background and text color for dark mode */
.couse-descpt-Box .ql-container {
    background-color: #1e1e1e; /* Dark background for the editor */
    color: #ffffff; /* White text color for readability */
}

/* Customize toolbar color, icons, and buttons */
.couse-descpt-Box .ql-toolbar {
    background-color: #333333; /* Dark toolbar background */
    border: none; /* Remove border for a cleaner look */
}

/* Target toolbar buttons and icons */
.couse-descpt-Box .ql-toolbar button {
    color: #ffffff !important; /* Set icon color to white */
    border: none;
    background: transparent;
}

/* SVG icon fill and stroke (to prevent icons from appearing as dots) */
.couse-descpt-Box .ql-toolbar button svg {
    fill: #ffffff !important; /* White fill for SVG icons */
    stroke: #ffffff !important; /* White stroke for SVG icons */
    width: 18px; /* Ensure icons are properly sized */
    height: 18px;
}

/* Hover effect for toolbar buttons */
.couse-descpt-Box .ql-toolbar button:hover {
    background-color: #4a4a4a; /* Darker gray for hover effect */
}

/* Active/focus effect for toolbar buttons */
.couse-descpt-Box .ql-toolbar button.ql-active,
.couse-descpt-Box .ql-toolbar button:focus {
    background-color: #5a5a5a; /* Slightly darker background when active */
}

/* Customize dropdown menu color */
.couse-descpt-Box .ql-picker-options {
    background-color: #333333;
    color: #ffffff;
    border: 1px solid #444444;
}

/* Set dropdown label and item color */
.couse-descpt-Box .ql-picker-label,
.couse-descpt-Box .ql-picker-item {
    color: #ffffff !important;
}

/* Placeholder text style */
.couse-descpt-Box .ql-editor.ql-blank::before {
    color: #888888; /* Lighter gray for placeholder text */
}

/* Customize editor's text style */
.couse-descpt-Box .ql-editor {
    font-size: 16px;
    line-height: 1.6;
    color: #ffffff; /* White text for readability */
}
